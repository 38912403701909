import * as React from 'react';
import store from '../../../redux/store';
import _ from 'lodash';
import { arrayMove, arrayRemove } from 'baseui/dnd-list';
import { setCardBackData } from '../../../redux/actions/appBasicControls';
const isObjectValidToPush = (options, optionsToPush, toaster) => {
  // FIRST CASE IF CARD BACK HAS WEB CARD WE CAN ALLOW TO ADD MORE
  let state = true;
  let isWebModulesAdded = options.options2.filter(el => !el.isDeleted).find((el) => el.__typename == 'WebCardModule');
  if (isWebModulesAdded) {
    showToaster(toaster, 'Web card does not allow more modules because this is full screen module', 'negative');
    state = false
  };
  if (optionsToPush.__typename == 'WebCardModule' && options.options2.filter(el => !el.isDeleted).length > 0) {
    showToaster(toaster, 'Web card can not be added with other modules', 'negative');
    state = false
  };

  let isVideoGalleryAdded = options.options2.filter(el => !el.isDeleted).find((el) => el.__typename == 'VideoGalleryCardModule');
  if (isVideoGalleryAdded) {
    showToaster(toaster, 'Video gallery does not allow more modules because this is full screen module', 'negative');
    state = false
  };
  if (optionsToPush.__typename == 'VideoGalleryCardModule' && options.options2.filter(el => !el.isDeleted).length > 0) {
    showToaster(toaster, 'Video gallery card can not be added with other modules', 'negative');
    state = false
  };

  let isAudioGalleryAdded = options.options2.filter(el => !el.isDeleted).find((el) => el.__typename == 'AudioCardModule');
  if (isAudioGalleryAdded) {
    showToaster(toaster, 'Audio gallery does not allow more modules because this is full screen module', 'negative');
    state = false
  };
  if (optionsToPush.__typename == 'AudioCardModule' && options.options2.filter(el => !el.isDeleted).length > 0) {
    showToaster(toaster, 'Audio gallery card can not be added with other modules', 'negative');
    state = false
  };

  let isQuizAdded = options.options2.filter(el => !el.isDeleted).find((el) => el.__typename == 'QuizMatchingQuestion' || el.__typename == 'QuizTextQuestion' || el.__typename == 'QuizOptionQuestion');
  if (isQuizAdded) {
    showToaster(toaster, 'Quiz does not allow more modules', 'negative');
    state = false
  };
  if ((optionsToPush.__typename == 'QuizMatchingQuestion' || optionsToPush.__typename == 'QuizTextQuestion' || optionsToPush.__typename == 'QuizOptionQuestion') && options.options2.filter(el => !el.isDeleted).length > 0) {
    showToaster(toaster, 'Quiz card can not be added with other modules', 'negative');
    state = false
  };

  return state;
}

const showToaster = (toaster, msg, method = 'info') => {
  let toastKey = toaster[method](
    <>
      {msg}
    </>,
    {
      overrides: {
        InnerContainer: {
          style: { width: "100%" }
        }
      }
    }
  );
  setTimeout(() => { toaster.clear(toastKey) }, 3000);
};

export const createEditorCardsFromCardModules = (card_content_modules = [], req, doAfterProcessing) => {
  // debugger;
  return card_content_modules.filter(el => el.__typename != 'DefaultQuizQuestion').map(el => {
    let f1, f1Index;
    if (el.content_module) {
      f1 = JSON.parse(JSON.stringify(req.options)).find(el1 => {
        return el1.__typename == el.content_module.__typename;
      });
      f1Index = JSON.parse(JSON.stringify(req.options)).findIndex(el1 => {
        return el1.__typename == el.content_module.__typename;
      });
      if (!f1) return null;
      f1.inputs = f1.inputs.map((el1, i1) => {
        let preProcessor = el.content_module ? req.options[f1Index].inputs[i1].preProcessor : null;
        let afterProcessor = el.content_module ? req.options[f1Index].inputs[i1].afterProcessor : null;
        if (el1.loadOptionsFromState) {
          try {
            el1.options = el1.pathToStoreValue
              .split('.')
              .reduce((o, i) => o[i], store.getState());
          } catch (e) {
            el1.options = [];
          }
        }
        el1.value = preProcessor
          ? preProcessor(el.content_module[el1.key], el1, el.content_module)
          : el.content_module[el1.key];
        if (doAfterProcessing && afterProcessor) el1.value = afterProcessor(el1.value);
        el1.afterProcessor = afterProcessor;
        return el1;
      });
      f1.dId = el.content_module ? el.content_module.id : el.id;
      f1.position = el.position;
    }
    else {
      f1 = JSON.parse(JSON.stringify(req.options)).find(el1 => {
        return el1.__typename == el.__typename;
      });
      f1Index = JSON.parse(JSON.stringify(req.options)).findIndex(el1 => {
        return el1.__typename == el.__typename;
      });
      f1.inputs = f1.inputs.map((el1, i1) => {
        if (el1.key == 'no_of_correct_answers') {
          el1.value = el.no_of_correct_answers;
          return el1;
        }
        if (el1.key == 'has_multiple_answers') {
          el1.value = el.has_multiple_answers;
          return el1;
        }
        if (el[el1.key] == undefined && el.options) {
          el1.type = 'QuizOption';
          el1.value = JSON.parse(el.options).map((element, i) => {
            return element;
          })
        }
        else if (el[el1.key] == undefined && el.pairs) {
          el1.type = 'MatchingPair';
          el1.value = JSON.parse(el.pairs).map((element, i) => {
            return element.push(element);
          })
        }
        else {
          el1.value = el[el1.key];
        };
        // if (el1.key == 'question_text') {
        //   el1.value = el1.value || req.title;
        // };
        return el1;
      })
      f1.dId = el.id;
      f1.position = 0;
    }
    return f1;
  }).filter(el => el);
}

export const getModuleMovementMethods = (options, setOptions, selectedMainOptionId, toaster, dispatch, onSave = () => { }, title) => {
  // debugger;
  const updateOptions = (key, data) => { };
  return {
    addNewToTheList: (sourceIndex, destinationIndex, cloneFrom, selectedMainOptionIdInternal = selectedMainOptionId) => {
      // debugger;
      if (cloneFrom) {
        sourceIndex = options.options1.findIndex(el => el.id == cloneFrom.id);
      }
      let data = _.cloneDeep(options['options1']).filter(el => {
        return el.parent == selectedMainOptionIdInternal;
      })[sourceIndex];
      data.dId = 'manual' + new Date().getTime() + 'id';
      data.updatedAt = new Date().getTime();
      data.position = options.options2.length;
      if (data.inputs[0] && data.inputs[0].key == 'question_text') {
        data.inputs[0].value = data.inputs[0].value || title;
      };
      if (cloneFrom) {
        data.inputs = data.inputs.map((el, i) => {
          el.value = cloneFrom.inputs[i].value;
          return el;
        });
      };
      if (isObjectValidToPush(options, data, toaster)) {
        let sortedArray = options.options2.sort(
          (a, b) => a.position - b.position
        );
        let newArr = sortedArray.map(el => { return { ...el } });
        newArr.splice(destinationIndex, 0, data);
        options.options2 = newArr.map((el, i) => {
          el.position = i;
          return el;
        });
        options['options2'] = options['options2'].map((el) => {
          let oldItem = sortedArray.find((el1) => el1.dId == el.dId);
          let oldPosition = oldItem ? oldItem.position : null;
          let newPosition = el.position;
          if (oldPosition !== newPosition) {
            el.updatedAt = new Date().getTime();
          };
          return el;
        });
        updateOptions('options2', options.options2);
        dispatch(setCardBackData(options.options2));
        onSave(options.options2);
      }
    },
    reOrderList: (destinationId, sourceIndex, destinationIndex) => {
      // debugger;
      let sortedArray = options[destinationId].sort(
        (a, b) => a.position - b.position
      );
      let newArr = arrayMove(sortedArray.map(el => { return { ...el } }), sourceIndex, destinationIndex);
      options[destinationId] = newArr.map((el, i) => {
        el.position = i;
        return el;
      });
      options[destinationId] = options[destinationId].map((el) => {
        let oldPosition = sortedArray.find((el1) => el1.dId == el.dId).position;
        let newPosition = el.position;
        if (oldPosition !== newPosition) {
          el.updatedAt = new Date().getTime();
        };
        return el;
      })
      updateOptions(destinationId, options[destinationId]);
      dispatch(setCardBackData(options.options2))
      onSave(options.options2)
    },
    removeFromEditor: sourceIndex => {
      options.options2 = options.options2
        .sort((a, b) => a.position - b.position)
        .filter(el => !el.isDeleted)
        .map((el, i) => {
          // el.position = i;
          if (i == sourceIndex) {
            el.updatedAt = new Date().getTime();
            el.isDeleted = true;
          }
          return el;
        });
      updateOptions('options2', options.options2);
      dispatch(setCardBackData(options.options2))
      onSave(options.options2)
    },
    undoDeleted: sourceIndex => {
      options.options2 = options.options2
        .sort((a, b) => a.position - b.position)
        .map((el, i) => {
          // el.position = i;
          if (i == sourceIndex) {
            el.updatedAt = new Date().getTime();
            el.isDeleted = false;
          }
          return el;
        });
      updateOptions('options2', options.options2);
      dispatch(setCardBackData(options.options2))
      onSave(options.options2)
    }
  }
}

export const cardBackListItemMoveMethods = {

  onMoveUp: (item, itemArr, callBack = () => { }) => {
    if (item.position - 1 < 0) return;
    let temp = itemArr.sort((a, b) => a.position - b.position);
    let filteredTemp = temp.filter(ele => {
      if (ele.position !== item.position) {
        return ele;
      }
    });
    filteredTemp.splice(item.position - 1, 0, item);
    let updatedTemp = filteredTemp.map((ele, indx) => ({
      ...ele,
      position: indx
    }));
    debugger;
    callBack(updatedTemp)
    return updatedTemp;
  },

  onMoveDowm: (item, itemArr, callBack = () => { }) => {
    let tempOptions = [...itemArr] || []
    if (item.position + 1 >= tempOptions.length) return;
    let temp = tempOptions.sort((a, b) => a.position - b.position);
    let filteredTemp = temp.filter(ele => {
      if (ele.position !== item.position) {
        return ele;
      }
    });
    filteredTemp.splice(item.position + 1, 0, item);
    let updatedTemp = filteredTemp.map((ele, indx) => ({
      ...ele,
      position: indx
    }));

    callBack(updatedTemp)
    return updatedTemp;
  },

  onDeleteItem: (item, itemArr, callBack = () => { }) => {
    let temp = itemArr.sort((a, b) => a.position - b.position);
    let filteredTemp = temp.filter(ele => {
      if (ele.position !== item.position) {
        return ele;
      }
    });
    let updatedTemp = filteredTemp.map((ele, indx) => ({
      ...ele,
      position: indx
    }));
    callBack(updatedTemp)
    return updatedTemp;
  }

}


export const scrollToElement = (scrollElement, elementName) => {
  debugger
  const lastContainer = document.querySelector(elementName);
  const scrollContainer = document.querySelector(scrollElement);
  if ((!lastContainer || lastContainer === null) || (!scrollContainer || scrollContainer === null)) return;
  scrollContainer.scrollTo({
    top: lastContainer.offsetTop,
    behavior: 'auto'
  });
}

export const scrollToBottom = (elementId, offset) => {
  const element1 = document.getElementById(elementId);
  var headerOffset = offset;
  var elementPosition = element1.scrollHeight;
  var offsetPosition = elementPosition - headerOffset;
  console.log('scroll param bottom', elementPosition, offsetPosition);
  element1.scrollTo({
    top: offsetPosition,
    behavior: "smooth"
  });
}

export const cookieTest = (iFrameUri, callBack) => {

  let messageHandler = (event) => {
    // check for trusted origins here     
    console.log('messageHandler', { event })
    const data = event.data
    callBack(data['result'])
    window.removeEventListener('message', messageHandler);
    document.body.removeChild(frame)

  }
  window.addEventListener('message', messageHandler);
  const frame = document.createElement('iframe')
  frame.src = iFrameUri
  frame.sandbox = "allow-scripts allow-same-origin"
  frame.style = `display:none`
  frame.onload = (e) => {
    frame.contentWindow.postMessage(JSON.stringify({ 'test': 'cookie' }), '*');
  }
  document.body.appendChild(frame)
}

export default cookieTest
